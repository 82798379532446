const theme = {
  defaultText: '#fff',
  backgroundColor: '#000',
  insetBackground: '#111',
  insetBorder: '#999',
  amountBorder: '#FF70CE',
  amountBackground: '#50200d',
  textColor: '#fff',
  textLight: '#999',
  accent: '#F56EEF',
  inputBorder: '#4a4c4e',
  inputText: '#fff',
  inputHighlightBorder: '#8578f3',
  inputBackground: 'transparent',
  buttonBackground: 'transparent',
  buttonBorder: '#4a4c4e',
  buttonBorderHover: '#999',
  mobileBreakpoint: '768px',
  secondaryTextColor: '#70d1ff',
  secondaryColor: '#70d1ff',
  secondaryBackground: '#025377',
  primaryColor: '#ff4d4f',
  primaryBackground: '#F56EEF',
  tertiaryColor: '#222',
  tertiaryBackground: 'rgba(255,255,255,.15)',
  borderRadius: '24px',
  errorColor: '#c62828',
  errorBackground: '#611212',
  primaryGradient: `linear-gradient(to right, #ff4d4f 0%, #b663a5 50%, #5d59c5 100%)`,
  boostColor: '#64C93B',
  acceptGradient: `linear-gradient(to right, #6553FB, #9662F9)`,
  // topicGradient: `linear-gradient(to right, #03ccfe, #09fea1)`,
  topicGradient: '#111',
  // topicTextColor: '#070758'
  topicTextColor: '#fff'
}

export const lightTheme = {
  ...theme,
  backgroundColor: '#fff',
  textColor: '#000'
}

export default theme
