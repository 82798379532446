import 'core-js/features/global-this'
import React, { Suspense } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { getApp } from 'firebase/app'
import {
  getFirestore,
  enableIndexedDbPersistence,
  initializeFirestore,
  connectFirestoreEmulator
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import ReactDOM from 'react-dom'
import './index.css'

import reportWebVitals from './reportWebVitals'
import { firebaseConfig } from './firebase'
import { FirebaseAppProvider, FirestoreProvider, useFirebaseApp, useInitFirestore } from 'reactfire'
import { ThemeProvider } from '@emotion/react'
import theme from './theme'

import { AlertProvider } from './contexts/AlertProvider'
import smoothscroll from 'smoothscroll-polyfill'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { BreakpointProvider } from 'react-socks'
import { HeadProvider } from 'react-head'
import Loading from './views/Loading'

const ErrorProvider = React.lazy(() => import('./contexts/ErrorProvider'))
const App = React.lazy(() => import('./App'))
const AuthProvider = React.lazy(() =>
  import('./contexts/AuthProvider').then(({ AuthProvider }) => ({ default: AuthProvider }))
)

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY
let stripePromise: any
if (key) {
  stripePromise = loadStripe(key)
} else {
  console.error('No Stripe key provided')
  stripePromise = Promise.reject()
}

smoothscroll.polyfill()

function FirebaseApp() {
  const { status, data: firestoreInstance } = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {})

    if (process.env.REACT_APP_ENABLE_FIREBASE_EMULATOR) {
      connectFirestoreEmulator(db, 'localhost', 8080)
    }
    return db
  })

  if (process.env.REACT_APP_ENABLE_FIREBASE_EMULATOR) {
    connectAuthEmulator(getAuth(), 'http://localhost:9099')
    connectFunctionsEmulator(getFunctions(), 'localhost', 5001)
  }

  if (status === 'loading') {
    return <Loading />
  }

  const app = getApp()
  getAnalytics(app)
  getPerformance(app)

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <Suspense fallback={<></>}>
        <Elements stripe={stripePromise}>
          <ThemeProvider theme={theme}>
            <ErrorProvider>
              <BreakpointProvider>
                <AlertProvider>
                  <Router>
                    <AuthProvider>
                      <HeadProvider headTags={[]}>
                        <App />
                      </HeadProvider>
                    </AuthProvider>
                  </Router>
                </AlertProvider>
              </BreakpointProvider>
            </ErrorProvider>
          </ThemeProvider>
        </Elements>
      </Suspense>
    </FirestoreProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseApp />
    </FirebaseAppProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
