import React, { useEffect, useState } from 'react'
import { Alert } from '../types/main'

export type ContextProps = {
  alerts: Alert[]
  // eslint-disable-next-line @typescript-eslint/ban-types
  addAlert: Function
}

export const AlertContext = React.createContext<Partial<ContextProps>>({})

export const AlertProvider = ({ children }: any) => {
  const [alerts, setAlerts] = useState<Alert[]>([])

  const addAlert = (alert: Alert) => {
    const id = Math.random().toString(36).substring(7)

    setAlerts((alerts) => [...alerts, { ...alert, id }])

    setTimeout(() => {
      setAlerts((alerts) => alerts.filter((a) => a.id !== id))
    }, alert.duration || 3000)

    return id
  }

  return (
    <AlertContext.Provider
      value={{
        addAlert,
        alerts
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}
