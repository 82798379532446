import RequestLogo from './RequestLogo'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { keyframes } from '@emotion/react'

const Styles = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 400px;
  margin: auto;

  justify-content: center;
  align-items: center;

  .dot {
    width: 25px;
    height: 25px;
    border-radius: 1em;
    &:nth-of-type(1) {
      background: #fe4d50;
    }
    &:nth-of-type(2) {
      background: #df5776;
    }
    &:nth-of-type(3) {
      background: #c56096;
    }
    &:nth-of-type(4) {
      background: #aa62aa;
    }
    &:nth-of-type(5) {
      background: #905fb3;
    }
    &:nth-of-type(6) {
      background: #725cbe;
    }
  }

  .loading-container {
    display: flex;
    flex-direction: row;
    div {
      margin: 1em;
    }
  }
`

const buildTransition = (duration: number) => ({
  repeat: Infinity,
  duration,
  ease: 'easeIn',
  repeatType: 'mirror' as const
})

const item = {
  hidden: { opacity: 0 },
  show: { opacity: 1 }
}

export default function Loading(props: any) {
  const duration = 0.5
  const transition = buildTransition(duration)
  return (
    <Styles>
      <motion.div
        className="loading-container"
        layout
        variants={item}
        initial="hidden"
        animate="show"
        transition={{ delay: 0.75 }}
      >
        <motion.div className="dot" variants={item} transition={{ ...transition, delay: 0 * duration }}></motion.div>
        <motion.div className="dot" variants={item} transition={{ ...transition, delay: 0.33 * duration }}></motion.div>
        <motion.div className="dot" variants={item} transition={{ ...transition, delay: 0.66 * duration }}></motion.div>
      </motion.div>
    </Styles>
  )
}
